<template>
  <div>
    <v-row class="my-10">
      <v-col cols="12" md="4">
        <div>
          <v-card class="mb-5">
            <v-img :src="foto" />
          </v-card>

          <v-card class="py-2 px-5 mb-5 text-center" color="utama" dark>
            <h4>{{ asn.nama }}</h4>
            <v-divider class="my-1"></v-divider>
            <span>{{ asn.nipBaru }}</span>
          </v-card>

          <!-- <v-card class="py-2 px-5" color="utama" dark>
            <h4>UNIT ORGANISASI</h4>

            <v-divider class="my-2"></v-divider>

            <small>{{ asn.unor.nama }}</small>
          </v-card> -->
        </div>
      </v-col>

      <v-col cols="12" md="8">
        <div class="text-center mb-5">
          <h1 class="font-weight-regular">SELAMAT DATANG</h1>
          <h3 class="font-weight-light">
            Kelola info, service, dan keamanan anda untuk membuat IDASN bekerja
            lebih baik untuk anda.
          </h3>
        </div>

        <v-col>
          <v-card class="pa-5">
            <h4>UBAH PASSWORD</h4>
            <v-divider class="my-2"></v-divider>
            <v-form ref="form" class="mt-5">
              <div class="mb-4">
                <v-text-field
                  v-model="oldPassword"
                  outlined
                  dense
                  :rules="fieldRules"
                  @click:append="showOld = !showOld"
                  label="Password Lama"
                  :append-icon="showOld ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="showOld ? 'text' : 'password'"
                  autocomplete="off"
                  class="mb-n3"
                ></v-text-field>
              </div>
              <div class="mb-4">
                <v-text-field
                  v-model="newPassword"
                  outlined
                  dense
                  :rules="fieldRules"
                  @click:append="showNew = !showNew"
                  label="Password Baru"
                  :append-icon="showNew ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="showNew ? 'text' : 'password'"
                  autocomplete="off"
                ></v-text-field>
              </div>

              <div class="text-right mt-5">
                <v-btn
                  v-if="btnLoading"
                  dark
                  color="utama"
                  depressed
                  @click="simpan()"
                >
                  Simpan
                </v-btn>
                <v-btn v-else color="utama" dark depressed loading>
                  Simpan
                </v-btn>
              </div>
            </v-form>
          </v-card>
        </v-col>
      </v-col>
    </v-row>

    <v-snackbar
      v-model="snackbar"
      :timeout="timeout"
      elevation="24"
      :color="alertColor"
    >
      <v-icon left v-if="alert">mdi-checkbox-marked-circle-outline</v-icon>
      <v-icon left v-else>mdi-close-circle</v-icon>
      {{ text }}

      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snackbar = false"> Close </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import Cookie from "@/helper/cookie.js";

export default {
  data: () => ({
    btnLoading: true,
    loading: true,
    session: "",
    token: "",

    alert: true,
    alertColor: "success",
    snackbar: false,
    text: "",
    timeout: 3000,

    foto: "",

    asn: {
      unor: {
        id: "",
        nama: "",
      },
    },

    showOld: false,
    showNew: false,

    oldPassword: "",
    newPassword: "",

    fieldRules: [(value) => !!value || "Tidak Boleh Kosong"],
  }),

  async mounted() {
    this.session = await JSON.parse(Cookie.dec(Cookie.get("myCookie")));
    this.token = await Cookie.get("token");
    this.getData();
  },

  methods: {
    getData() {
      this.loading = true;
      const url = process.env.VUE_APP_AUTH + "user/info";
      this.http
        .get(url, {
          headers: {
            Authorization: "Bearer " + this.token,
          },
        })
        .then((response) => {
          this.asn = response.data.mapData;
          this.getFoto();
          this.loading = false;
        })
        .catch(function (error) {
          if (error.response) {
            console.log(error.response.status);
          } else {
            console.log("Error", error.message);
          }
        });
    },

    getFoto() {
      const url =
        "https://api.idasn.pohuwatokab.go.id/idasn/photos/" + this.asn.nipBaru;

      this.http
        .get(url, {
          headers: {
            Authorization: "Bearer " + this.token,
          },
          responseType: "blob",
        })
        .then((response) => {
          this.foto = URL.createObjectURL(response.data);
        })
        .catch((error) => {
          if (error.response.status == 401) {
            // Auth.logout(this.token);
          } else if (error.response) {
            console.log(error.response.status);
          } else {
            console.log("Error", error.message);
          }
        });
    },

    simpan() {
      this.btnLoading = false;
      const url =
        process.env.VUE_APP_AUTH + "security/users/" + this.asn.nipBaru;

      let data = {
        oldPassword: this.oldPassword,
        newPassword: this.newPassword,
      };
      this.http
        .put(url, data, {
          headers: {
            Authorization: "Bearer " + this.token,
          },
        })
        .then((res) => {
          if (res.data.success) {
            this.alert = true;
            this.alertColor = "success";
            this.snackbar = true;
            this.text = res.data.message;
          } else {
            this.alert = false;
            this.alertColor = "error";
            this.snackbar = true;
            this.text = res.data.message;
          }
          this.btnLoading = true;
        })
        .catch((error) => {
          this.alert = false;
          this.alertColor = "error";
          this.snackbar = true;
          this.text = error.response.data.message;
          this.btnLoading = true;
          console.log(error);
        });
    },
  },
};
</script>
